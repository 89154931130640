<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Income Tax Band</h1>
            </div>
            <!-- <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
            </div> -->
          </div>
          <div class="col-md-6 ml-auto mr-auto">
            <v-card class="mx-auto my-12">
                <v-form ref="IncomeTaxBandForm" v-model="valid">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12">
                            <TextField v-model="formData.rate" :label="'Rate'" />
                            </v-col>
                            <v-col cols="12">
                            <TextField v-model="formData.amount" :label="'Amount'" />
                            </v-col>
                            <v-col cols="12">
                            <TextField v-model="formData.order" :label="'Order'" />
                            </v-col>
                        </v-row>
                        <v-col cols="9 mt-5" class="d-flex justify-end">
                            <Button
                            :label="'Submit'"
                            :btnType="'Submit'"
                            @onClick="dialog = true"
                            :disabled="!valid"
                            :isLoading="loadingData"
                            class="mr-4"
                            />
                            <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                        </v-col>
                    </v-card-text>
                </v-form>
            </v-card>
          </div>
          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
           <v-data-table v-else
                         :headers='headers'
                         :items="incomeTaxBand"
                         item-key="id" :width="400">
                    <template v-slot:[`item.amount`]="{ item }" >
                      {{item.amount | formatMoney}}
                  </template>
                  <template v-slot:[`item.approvedDate`]="{ item }" >
                      {{item.approvedDate | formatDate}}
                  </template>
                  <template v-slot:[`item.approved`]="{ item }" >
                      <span v-if="item.approved === true">Approved</span>
                      <span v-if="item.approved === false">Pending</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex">
                        <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2" />
                        <Button :btnType="'Cancel'" :label="'Delete'" @onClick="delItem(item)" class="mr-2" />
                    </div>
                    </template>
           </v-data-table>
            <Dialog ref="incomeTaxBandModal" :title="'Edit Income Tax Band'" :width="700">
                <v-row>
                    <TextField v-model="editedData.rate" :label="'Rate'"/>
                </v-row>
                <v-row>
                    <TextField v-model="editedData.amount" :label="'Amount'"/>
                </v-row>
                <v-row>
                    <TextField v-model="editedData.order" :label="'Order'"/>
                </v-row>
                <template v-slot:footer>
                <Button :btnType="'Submit'" :label="'Update'" @onClick="update" :isLoading="loading" class="mr-2"/>
                <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel" />
                </template>
            </Dialog>
        </v-container>
      </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to delete?'" :isLoading="loadingDel" :dialog="dialogDel" :btnTitle="'Yes'" @close="dialogDel = false" @btnAction="onDelete"/>

    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loadingData" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
// import SelectField from '@/components/ui/form/SelectField.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { requestService } from '@/services'
import TextField from '@/components/ui/form/TextField'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog,
    Dialog
    // SelectField
  },
  data () {
    return {
      formData: {
        rate: '',
        amount: '',
        order: ''
      },
      editedData: {
        id: 0,
        rate: 0,
        amount: 0,
        order: 0
      },
      pay: [],
      loadingData: false,
      loadingDel: false,
      loading: false,
      dialog: false,
      dialogDel: false,
      valid: false,
      incomeTaxBand: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Rate',
          value: 'rate'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Order',
          value: 'order'
        },
        {
          text: 'Status',
          value: 'approved'
        },
        {
          text: 'Approved By',
          value: 'approvedBy'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    // user () {
    //   return this.$store.getters.users
    // }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    getIncomeTaxBands () {
      this.loadingData = true
      requestService.getIncomeTaxBand().then(result => {
        this.incomeTaxBand = result.data
        console.log('IncomeTax', this.incomeTaxBand)
      }).catch(error => {
        console.log(error)
      })
      this.loadingData = false
    },
    Add () {
      this.$router.push('/Income-Tax/pending')
    },
    // getIncomeTaxBands () {
    //   requestService.getAllPendingIncomeTaxBand().then(result => {
    //     this.incomeTaxBand = result.data.items
    //     console.log('IncomeTax', this.incomeTaxBand)
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // },

    edit (item) {
      this.editedData.id = item.id
      this.editedData.rate = item.rate
      this.editedData.amount = parseInt(item.amount)
      this.editedData.order = item.order
      this.$refs.incomeTaxBandModal.toggleModal()
    },
    delItem (item) {
      this.pay = item
      this.dialogDel = true
    },
    onDelete () {
      this.loadingDel = true
      requestService.deleteIncomeTaxBand(this.pay).then(() => {
        this.showAlertMessage('Income Tax Band successfully Deleted', 'success')
        this.loadingDel = false
        this.dialogDel = false
        this.getIncomeTaxBands()
      }).catch(error => {
        console.log(error)
        this.loadingDel = false
      })
    },
    cancel () {
      this.$refs.incomeTaxBandModal.toggleModal()
    },
    update () {
      this.loading = true
      const data = {
        Id: this.editedData.id,
        Rate: parseInt(this.editedData.rate),
        Amount: parseInt(this.editedData.amount),
        Order: parseInt(this.editedData.order)
      }
      requestService.updateIncomeTaxBand(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Updated', 'success')
        this.loading = false
        this.getIncomeTaxBands()
        this.cancel()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    onSave () {
      this.loadingData = true
      console.log('Ok')
      const data = {
        Rate: this.formData.rate,
        Amount: this.formData.amount,
        Order: this.formData.order
      }
      console.log("I'm Here")

      requestService.addIncomeTaxBand(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Added', 'success')
        this.getIncomeTaxBands()
      }).catch((error) => {
        console.log('Error: ', error)
        this.showAlertMessage('Income Tax Band failed', 'error')
        this.loadingData = false
        this.dialog = false
      }).finally(() => {
        this.loadingData = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.IncomeTaxBandForm.reset()
      this.formData.amount = ''
      this.formData.rate = ''
      this.formData.order = ''
    }
  },
  mounted () {
    this.getIncomeTaxBands()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }
  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
